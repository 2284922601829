import React from 'react';
import { Line } from 'react-chartjs-2';
import { Radar } from 'react-chartjs-2';
import { Button, Card } from '@material-ui/core';
import { PageTitle } from 'layout-components';
import UserContext from '../../providers/UserContext';
import { NavLink } from 'react-router-dom';
import firebase from 'firebase';
import {FirebaseDatabaseProvider, FirebaseDatabaseNode } from '@react-firebase/database'
import {config} from '../../providers/firebase-config'
import { TagCloud } from 'react-tagcloud'
import MoodChart1 from './component/MoodChart1';



const MCdata = (d,user) => {
  if(!d.value) return null
  d.value = d.value[user]
  //const subEmotions = Object.keys(d.value || {}).map(e=>d.value[e].subEmotion)
  const mainEmotions = Object.keys(d.value || {})
  .map(e=>({label:d.value[e].mainEmotion,
  time: d.value[e].time}))
  .filter(e => !!e.label && e.label.length > 0)
    console.log(mainEmotions)

  const subEmotions = Object.keys(d.value || {})
  .map(e=>({label:d.value[e].subEmotion,
  time: d.value[e].time}))
  .filter(e => !!e.label && e.label.length > 0)
  const mainEmotionsMonth = mainEmotions.filter(e => e.time > Date.now() - 2629745999)
  const mainEmotionsWeek = mainEmotions.filter(e => e.time > Date.now() - 604800000)
  const wCountsMonth = mainEmotionsMonth.reduce((acc, e) => {
    const labels= ['Angry', 'Fear', 'Sadness', 'Disgust', 'Enjoyment', 'Other']
    let ind = labels.indexOf(e.label)
    if(ind === -1) ind =5
    acc[ind] = acc[ind] + 1
    return acc
  }, [0,0,0,0,0,0])
  console.log(wCountsMonth)
  const totalCountsMonth = wCountsMonth.reduce((acc,e) => acc+e,0)
  const wCountsWeek = mainEmotionsWeek.reduce((acc, e) => {
    const labels= ['Angry', 'Fear', 'Sadness', 'Disgust', 'Enjoyment', 'Other']
    let ind = labels.indexOf(e.label)
    if(ind === -1) ind =5
    acc[ind] = acc[ind] + 1
    return acc
  }, [0,0,0,0,0,0])
  const totalCountsWeek = wCountsWeek.reduce((acc,e) => acc+e,0)
  //console.log(subEmotions)
  const data = {
    labels: ['Angry', 'Fear', 'Sadness', 'Disgust', 'Enjoyment', 'Other'],
    datasets: [
      {
        label: 'Last Week (%)',
        backgroundColor: 'rgba(179,181,198,0.2)',
        borderColor: 'rgba(179,181,198,1)',
        pointBackgroundColor: 'rgba(179,181,198,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(179,181,198,1)',
        data: wCountsWeek.map(e => e/totalCountsWeek*100)
      },
      {
        label: 'Last Month (%)',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        pointBackgroundColor: 'rgba(255,99,132,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(255,99,132,1)',
        data: wCountsMonth.map(e => e/totalCountsMonth*100)
      }
    ]
  };
  console.log(wCountsWeek, wCountsWeek.map(e => e/totalCountsWeek*100))
  let subwc = subEmotions.reduce((acc,e) => {
    acc[e.label] = (acc[e.label] || 0) + 1
    return acc
  }, {})
  subwc = Object.keys(subwc).map(e => ({value: e, count: subwc[e]}))
  console.log(subwc)
  console.log(data)
  return (
   <> <Radar data={data} />
   <br></br>
    <TagCloud
    minSize={12}
    maxSize={35}
    tags={subwc || []}
   
  />
  </>
  )
}


export default function MoodChart() {
  
  const user = React.useContext(UserContext)
  //path={(user.userProps.email || '').split('@')[0]+'/mood/'}
  return (
    <>

      <PageTitle
        titleHeading="My Mood Chart"
        titleDescription="(Rolling updated based on milliseconds)"
      />

      <FirebaseDatabaseProvider firebase={firebase} {...config}>
            <Card className="rounded shadow-xl card-box-hover-rise">

              <div className="p-3 p-lg-4 text-center " >
                <h4 className="font-size-xl py-3">
                  How am I doing recently?
                </h4>
                <br></br>
                <FirebaseDatabaseNode
              path={'mood/'+(user.userProps.email || '').split('@')[0]+'/'}
             // limitToFirst={this.state.limit}
              // orderByKey
           //   orderByValue={"created_on"}
            > 
            {d => {
                console.log(d)
              return MCdata(d, (user.userProps.email || '').split('@')[0])
                }}

              
            </FirebaseDatabaseNode>
                
                <br></br>
                <NavLink
                  to="/MoodTrackerPage">
                  <Button className="btn-primary text-uppercase btn-gradient bg-night-fade font-weight-bold font-size-sm p-3"> Record my mood now</Button>
                </NavLink>
              </div>
            </Card>
      </FirebaseDatabaseProvider>

      



    </>
  );
}
