import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { PageTitle } from 'layout-components';
import { NavLink } from 'react-router-dom';
import { Grid, TextField, InputLabel, Card, MenuItem, FormControl, Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { ExampleWrapperSimple } from 'layout-components';
import InputMask from './component/InputMask';




export default function MoodChart() {

  

  return (
    <>
      <img style={{ width: '100%' }} src="ComingSoon2.jpg"></img>

      <NavLink
        to="/Dashboard">
        <div className="text-center mb-4">
          <Button className="btn-primary text-uppercase btn-gradient bg-happy-green font-weight-bold font-size-sm my-3">
            Ok
        </Button>
        </div>
      </NavLink>
    </>
  );
}
