import React from 'react';
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Card, CardContent, Button,  Dialog, DialogActions, DialogContent, DialogTitle,  } from '@material-ui/core';
import {useState } from 'react'
import DialogContentText from '@material-ui/core/DialogContentText';
import { Pagination } from '@material-ui/lab';
import Trend from 'react-trend';
import CountUp from 'react-countup';
import { NavLink } from 'react-router-dom';
import { withUser } from '../../../providers/withUser';
import UserContext from 'providers/UserContext';

function LivePreviewExample(props) {
  const {user} = props
  const [pie, setPie] = React.useState(
    []
  )

  const getPie = (piename) => {
    console.log(pie)
    const pies = pie.filter(e => e.stage == piename)
    return pies.length == 0 ? null : pies[pies.length-1]
  }
  const [dialogContent, setDialogContent] = useState({})
  const [open3, setOpen3] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const descriptionElementRef = React.useRef(null);
  const handleClickOpen3 = (scrollType) => () => {
    setOpen3(true);
    setScroll(scrollType);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };


  React.useEffect(
    async () => {
      //https://script.google.com/macros/s/AKfycbzepzPz9FHeiUEjjHR4vEP6qU_MBnjfyJcNywlAx1RpJf1WZsmpRftFwg/exec?action=read&table=Sheet1
      const data = await axios.get('https://lsc-secure.rubbity.io/script.google.com/macros/s/AKfycbwxWFnf2qGxiw6hvSU-i5SQu-zWBztb1Z-kMSVAbbbkn2-bLjdlHDbFCI0ukuOu-ljF/exec?action=read&table=data')
      
      console.log('sf',data.data.data, user.userProps.email, user)
      setPie(data.data.data.filter(e=>e.user == user.user.email))
    }, [])
  return (
    <>
      <Card style={{ backgroundColor: 'transparent' }} className="mb-spacing-6-x2">

        <div className="card-header" style={{ backgroundColor: 'transparent' }}>
          <div className="card-header--title" style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'space-between' }}>
            <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
              P.I.E. Record
            </h4>

            <Button size="small" className="btn-neutral-primary " onClick={props.openUpdate}>Update</Button>

          </div>
        </div>


        <CardContent style={{ backgroundColor: 'transparent' }} className="p-0">
          <div className="table-responsive-md ml-4">
            <Table className="table table-borderless table-hover table-striped text-nowrap mb-0">
              <thead>
                <tr>
                  <th className="text-left" >Stage </th>
                  <th className="text-left" >Details </th>
                  <th className="text-left" >Last update</th>

                </tr>
              </thead>
              <tbody>
                {['a'].map(e => <><tr>
                  <td >
                    <div>
                      <a className="font-weight-bold text-black">  F.4 Planning</a>
                      {/* <a
                        href="AnnouncementPage"
                        onClick={(eh) => {
                          eh.preventDefault()
                          localStorage.setItem('currentAnnouncement', JSON.stringify(e))
                          window.location.href = 'AnnouncementPage'
                        }}
                        className="font-weight-bold text-black"
                        title="...">
                        {e['Post on behalf of...(Committee)']}
                    </a>
                      <span className="text-black-50 d-block">
                        {e['Announcement title']}
                    </span> */}
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">{getPie('F4plan') ? <span onClick={()=>{
                      setDialogContent(getPie('F4plan'))
                      setOpen3(true)
                    }}>View more</span> : '---'}</span>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">{getPie('F4plan') ? new Date(getPie('F4plan')['update_date']).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : '---'}</span>
                  </td>

                </tr>
                  <tr className="divider" /></>)}

                <tr>
                  <td >
                    <div>
                      <a className="font-weight-bold text-black">  F.4 Implementation</a>
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">{getPie('F4implementation') ? <span onClick={()=>{
                      setDialogContent(getPie('F4implementation'))
                      setOpen3(true)
                    }}>View more</span> : '---'}</span>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">{getPie('F4implementation') ? new Date(getPie('F4implementation')['update_date']).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : '---'}</span>
                  </td>
                </tr>
                <tr className="divider"/>

                <tr>
                  <td >
                    <div>
                      <a className="font-weight-bold text-black">  F.4 Evaluation</a>
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">{getPie('F4evaluation') ? <span onClick={()=>{
                      setDialogContent(getPie('F4evaluation'))
                      setOpen3(true)
                    }}>View more</span> : '---'}</span>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">{getPie('F4evaluation') ? new Date(getPie('F4evaluation')['update_date']).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : '---'}</span>
                  </td>
                </tr>
                <tr className="divider"/>

                <tr>
                  <td >
                    <div>
                      <a className="font-weight-bold text-black">  F.5 Planning</a>
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">{getPie('F5plan') ? <span onClick={()=>{
                      setDialogContent(getPie('F5plan'))
                      setOpen3(true)
                    }}>View more</span> : '---'}</span>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">{getPie('F5plan') ? new Date(getPie('F5plan')['update_date']).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : '---'}</span>
                  </td>
                </tr>
                <tr className="divider"/>
                <tr>
                  <td >
                    <div>
                      <a className="font-weight-bold text-black">  F.5 Implementation</a>
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">{getPie('F5implementation') ? <span onClick={()=>{
                      setDialogContent(getPie('F5implementation'))
                      setOpen3(true)
                    }}>View more</span> : '---'}</span>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">{getPie('F5implementation') ? new Date(getPie('F5implementation')['update_date']).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : '---'}</span>
                  </td>
                </tr>
                <tr className="divider"/>

                <tr>
                  <td >
                    <div>
                      <a className="font-weight-bold text-black">  F.5 Evaluation</a>
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">{getPie('F5evaluation') ? <span onClick={()=>{
                      setDialogContent(getPie('F5evaluation'))
                      setOpen3(true)
                    }}>View more</span> : '---'}</span>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">{getPie('F5evaluation') ? new Date(getPie('F5evaluation')['update_date']).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : '---'}</span>
                  </td>
                </tr>
                <tr className="divider"/>
                {/*<tr>*/}
                {/*  <td >*/}
                {/*    <div>*/}
                {/*      <a*/}
                {/*        href="#/"*/}
                {/*        onClick={(e) => e.preventDefault()}*/}
                {/*        className="font-weight-bold text-black"*/}
                {/*        title="...">*/}
                {/*        1C Form Teacher*/}
                {/*    </a>*/}
                {/*      <span className="text-black-50 d-block">*/}
                {/*        Hand in your One-Page Profile*/}
                {/*    </span>*/}
                {/*    </div>*/}
                {/*  </td>*/}
                {/*  <td className="text-left">*/}
                {/*    <span className="font-weight-bold text-primary">3 Jan 2021</span>*/}
                {/*  </td>*/}

                {/*  <td className="text-center">*/}
                {/*    <div className=" badge badge-warning">recall</div>*/}
                {/*  </td>*/}
                {/*</tr>*/}
                {/*<tr className="divider"></tr>*/}
              </tbody>
            </Table>
          </div>
          <br></br>
        </CardContent>
      </Card>
      <Dialog
          open={open3}
          onClose={handleClose3}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description">
          <DialogTitle id="scroll-dialog-title">Confirm submission</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}>

    
              <b>Details:</b> <p>{dialogContent.details}</p>
              <b>Insights:</b> <p>{dialogContent.insight}</p>

            </DialogContentText>
          </DialogContent>
          <DialogActions className="bg-secondary p-4">
            <Button
              onClick={handleClose3}
              size="small"
              className="btn-outline-dark mr-3">
              Close
            </Button>
            
          </DialogActions>
        </Dialog>
    </>
  );
}

export default LivePreviewExample
