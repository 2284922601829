import React from 'react';
import axios from 'axios';

export function withUser(WrappedComponent: any) {
  // ...and returns another component...
  return class extends React.Component {
    state={
      user: JSON.parse(localStorage.getItem('user') || '{}'),
      userProps: {}
    }
    props:{}
    constructor(props: any) {
      super(props);
      this.props = props;
    }

    async componentDidMount() {
      if(!this.state.user.email && window.location.href.indexOf('Login') === -1) {
        window.location.href = '/Login'
      } else if (this.state.user.email && window.location.href.indexOf('Login') !== -1) {
        window.location.href = '/Dashboard'
      }
      let userProps
      if(localStorage.getItem('userProps') && JSON.parse(localStorage.getItem('userProps') || '[]').length > 0){
        console.log('im here 1')
        userProps = JSON.parse(localStorage.getItem('userProps') || '[]')
      }else {
        console.log('im here 2')
        userProps = await axios.get(
        'https://script.google.com/macros/s/AKfycby6oaQC7ciOPq0YWKBp0ZLK7OJlY8Yw-bEYcaXZ8fXYAL2PZc3S0xeCbPdi2YDzHgY0/exec?action=read&table=users'
      )
      console.log(userProps.data)
      userProps = userProps.data.data.filter((d:any) => d.email == this.state.user.email)
      localStorage.setItem('userProps',JSON.stringify(userProps))
      }
       
      console.log(userProps)
      this.setState({
        userProps: (userProps)[0]
      })
    }

    componentWillUnmount() {

    }


    render() {
      // ... and renders the wrapped component with the fresh data!
      // Notice that we pass through any additional props
      return <WrappedComponent user={this.state.user} userProps={this.state.userProps} {...this.props} />;
    }
  };
}
