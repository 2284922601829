import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, } from '@material-ui/core';
import { PageTitle } from 'layout-components';
import { NavLink } from 'react-router-dom';
import { Grid, TextField, InputLabel, Card, MenuItem, FormControl, Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { ExampleWrapperSimple } from 'layout-components';
import InputMask from './component/InputMask';
import axios from 'axios';
import {v4} from 'uuid'
import UserContext from 'providers/UserContext';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import DialogContentText from '@material-ui/core/DialogContentText';
import WhatsNew from './component/whats-new';

const marks = [
  {
    value: -5,
    label: 'I dont like it.'
  },
  {
    value: -2.5,
    label: 'Not Really'
  },
  {
    value: 0,
    label: 'It is okay.'
  },
  {
    value: 2.5,
    label: 'I Like it.'
  },
  {
    value: 5,
    label: 'It is awesome!'
  }
];

function valuetext(value) {
  return <span>{{ value }}</span>;
}

export default function MoodChart() {

  const user = React.useContext(UserContext)
  const created_date = Date.now();
  const [source, setSource] = useState('');
  const [readDate, setReadDate] = useState(new Date());
  const handleDateChange = (date) => {
    setReadDate(date);
  };
  const [message, setMessage] = useState('');
  const [knowMore, setKnowMore] = useState('');


  const handleChange = (event) => {
    setSource(event.target.value);
  }

  const [openUpdate, setOpenUpdate] = useState(false)

  // Modal
  const [open3, setOpen3] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const handleClickOpen3 = (scrollType) => () => {
    setOpen3(true);
    setScroll(scrollType);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open3) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open3]);
  const myRef = React.useRef(null)

   const executeScroll = () => myRef.current.scrollIntoView()

  return (
    <>
      <PageTitle
        titleHeading="Service Learning"
        titleDescription="Plan . Implementation . Evaluation"
      />
      <WhatsNew user={user} openUpdate={()=>{
        setOpenUpdate(true)
        setTimeout(executeScroll,1 )
      }} />
<div ref={myRef} style={{display: openUpdate ? 'block': 'none'}}>
      <h4 className="font-size-lg mb-0 py-3 font-weight-bold" id="update" >
        Stage
      </h4>
      <ExampleWrapperSimple>
        <FormControl fullWidth variant="standard">
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={source}
            onChange={handleChange}
            label="Age">
            <MenuItem value={'F4plan'}>F.4 Plan</MenuItem>
            <MenuItem value={'F4implementation'}>F.4 Implementation</MenuItem>
            <MenuItem value={'F4evaluation'}>F.4 Evaluation</MenuItem>
            <MenuItem value={'F5plan'}>F.5 Plan</MenuItem>
            <MenuItem value={'F5implementation'}>F.5 Implementation</MenuItem>
            <MenuItem value={'F5evaluation'}>F.5 Evaluation</MenuItem>
          </Select>
        </FormControl>
      </ExampleWrapperSimple>

      {/* <h4 className="font-size-lg mb-0 mt-5 py-3 font-weight-bold">
        Do you like the book / article?
      </h4>

      <ExampleWrapperSimple>
        <Grid container spacing={10} justify="center">
          <Grid item md={10}>
            <Slider
              className="slider-primary my-3"
              defaultValue={0}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              step={2.5}
              marks={marks}
              min={-5}
              max={5}
              onChange={(e, v) => {
                setlikeValue(v)
                console.log(v)
              }}
            />
          </Grid>
        </Grid>
      </ExampleWrapperSimple> */}

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        Details
      </h4>
      <ExampleWrapperSimple>
        <TextField
          className="my-3"
          fullWidth
          id="filled-textarea"
          label=""
          placeholder=""
          multiline
          rowsMax="4"
          onChange={(event) => {
            event.persist(
            )
            setMessage(event.target.value)
          }}
        />
      </ExampleWrapperSimple>

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        What are your insights?
      </h4>
      <ExampleWrapperSimple>
        <TextField
          className="my-3"
          fullWidth
          id="filled-textarea"
          label=""
          placeholder=""
          multiline
          rowsMax="4"
          onChange={(event) => {
            event.persist(
            )
            setKnowMore(event.target.value)
          }}
        />
      </ExampleWrapperSimple>


      <div className="text-center mb-4">
        <Button onClick={handleClickOpen3('paper')} className="btn-primary text-uppercase font-weight-bold font-size-sm my-3">
          Submit
        </Button>
        <Dialog
          open={open3}
          onClose={handleClose3}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description">
          <DialogTitle id="scroll-dialog-title">Confirm submission</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}>

              <b>Stage:</b> {source}<br></br>
              <b>Details:</b> <p>{message}</p>
              <b>Insights:</b> <p>{knowMore}</p>

            </DialogContentText>
          </DialogContent>
          <DialogActions className="bg-secondary p-4">
            <Button
              onClick={handleClose3}
              size="small"
              className="btn-outline-dark mr-3">
              Cancel
            </Button>
            <Button
              onClick={
                async () => {
                  const url = `https://script.google.com/macros/s/AKfycbwxWFnf2qGxiw6hvSU-i5SQu-zWBztb1Z-kMSVAbbbkn2-bLjdlHDbFCI0ukuOu-ljF/exec?action=insert&table=data&data={"id":"${v4()}","user":"${user.userProps.email}","update_date":"${Date().toLocaleString('en-US', {year: 'numeric', month: 'long', day: 'numeric' })}","stage":"${source}","details":"${message}","insight":"${knowMore}"}`
                  await axios.get(url)
                  window.location.href='ServiceLearning'
                }
              }
              className="btn-success">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      </div>

    </>
  );
}
