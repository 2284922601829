import React from 'react';
import { PageTitle } from 'layout-components';
import WhatsNew from './component/whats-new';
import BadgeCarousels from'./component/badge-carousels';
import Lessons from'./component/lessons';
import Tasks from'./component/tasks';
import LeaderboardList from './component/leaderboard-list';

import DashboardCommerce3 from '../../example-components/DashboardCommerce/DashboardCommerce3';
import DashboardCommerce4 from '../../example-components/DashboardCommerce/DashboardCommerce4';
import DashboardCommerce5 from '../../example-components/DashboardCommerce/DashboardCommerce5';
import DashboardCommerce6 from '../../example-components/DashboardCommerce/DashboardCommerce6';
export default function DashboardCommerce() {
  return (
    <>
      <PageTitle
        titleHeading="Leaderboard"
      />
      <img style={{ width: '100%' }} src="coming soon.png"></img>
      {/* <LeaderboardList />       */}


    </>
  );
}
