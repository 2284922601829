import React, { Component } from 'react'
import { GoogleLogin, GoogleLogout } from 'react-google-login';


const CLIENT_ID = '216831241010-qr53h8tgqv3veanmqboa0522j0oojio4.apps.googleusercontent.com';


class GoogleBtn extends Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      isLogined: false,
      accessToken: ''
    };

    this.login = this.login.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
    this.logout = this.logout.bind(this);
    this.handleLogoutFailure = this.handleLogoutFailure.bind(this);
  }

  login (response) {
    console.log(response)
    if(response.accessToken){
      this.setState(state => ({
        isLogined: true,
        accessToken: response.accessToken
      }));
      localStorage.setItem('user',JSON.stringify({...response.profileObj,accessToken:response.accessToken}))
      window.location.href = 'Dashboard'
      //this.props.shit.appActions.goToScreen('moodboardstudent', { transitionId: 'fadeIn' });
    }
  }

  logout (response) {
    this.setState(state => ({
      isLogined: false,
      accessToken: ''
    }));
  }

  handleLoginFailure (response) {
    console.log(response)
    alert('Failed to log in')
  }

  handleLogoutFailure (response) {
    alert('Failed to log out')
  }

  render() {
    return (
      <div>

        { this.state.isLogined ?
          <GoogleLogout
            clientId={ CLIENT_ID }
            buttonText='Logout'
            onLogoutSuccess={ this.logout }
            onFailure={ this.handleLogoutFailure }
          >
          </GoogleLogout>: <GoogleLogin
            clientId={ CLIENT_ID }
            buttonText='Login'
            onSuccess={ this.login }
            onFailure={ this.handleLoginFailure }
            cookiePolicy={ 'single_host_origin' }
            responseType='code,token'
          />
        }


      </div>
    )
  }
}

export default GoogleBtn;
