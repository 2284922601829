import React, { useState } from 'react';
import { Button, Switch, TextareaAutosize } from '@material-ui/core';
import { PageTitle } from 'layout-components';
import { NavLink } from 'react-router-dom';
import { Grid, TextField, InputLabel, Card, MenuItem, FormControl, Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { ExampleWrapperSimple } from 'layout-components';
import InputMask from './component/InputMask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileUpload from './component/FileUpload';
import UserContext from 'providers/UserContext';
import axios from 'axios';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';




export default function MoodChart() {
  const [forTeachersOnly, setForTeachersOnly] = useState(false)
  const [source, setSource] = useState('');
  const user = React.useContext(UserContext)

  const [selectedClasses, setSelectedClasses] = useState([])
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);
  const handleChange = (event) => {
    setSource(event.target.value);
  };

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [start_posting, setStartPosting] = useState('')
  const [stop_posting, setStopPosting] = useState('')
  const [links, setLinks] = useState([])
  const [tempLink, setTempLink] = useState('')
  const [to, setTo] = useState('')
  const [committee, setCommittee] = useState('')

  const [startDate, setStartDate] = useState(new Date());
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const [stopDate, setStopDate] = useState(new Date());
  const handleStopDateChange = (date) => {
    setStopDate(date);
  };


  return (
    <>
      <PageTitle
        titleHeading="Annoucement Posting Form"
        titleDescription="Please fill in related information and the announcement will be posted after approval."
      />

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        Announcement Title (*required)
      </h4>
      <ExampleWrapperSimple>
        <TextField
          className="my-3"
          fullWidth
          id="filled-textarea"
          label=""
          placeholder=""
          onChange={(event) => {
            event.persist(
            )
            setTitle(event.target.value)
          }}
        />
      </ExampleWrapperSimple>

      <br></br>
      <br></br>

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        Announcement Content(*required)
      </h4>
      <ExampleWrapperSimple>
        <TextareaAutosize
          className="my-3"
          fullWidth
          style={{width: '100%'}}
          id="filled-textarea"
          label=""
          placeholder=""
          onChange={(event) => {
            event.persist(
            )
            setContent(event.target.value)
            console.log(event.target.value)
          }}
        />

      </ExampleWrapperSimple>
      <br></br>
      <br></br>
      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        Post Date(*required)
      </h4>
      <ExampleWrapperSimple>
        <div className="d-flex align-items-center flex-wrap">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="m-4">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Start Date"
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>

        <div className="d-flex align-items-center flex-wrap">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="m-4">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="End Date"
                value={stopDate}
                onChange={handleStopDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
      </ExampleWrapperSimple>

      <br></br>
      <br></br>
      
      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        To..(*required)
      </h4>
      <div className="d-flex justify-content-center">
        <Grid container spacing={2}>
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('1A') !== -1) {
                  state.splice('1A', 1)
                  return state
                }
                state.push('1A')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('1A') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">1A</div>

          </a>
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('1B') !== -1) {
                  state.splice('1B', 1)
                  return state
                }
                state.push('1B')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('1B') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">1B</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('1C') !== -1) {
                  state.splice('1C', 1)
                  return state
                }
                state.push('1C')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('1C') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">1C</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('1D') !== -1) {
                  state.splice('1D', 1)
                  return state
                }
                state.push('1D')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('1D') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">1D</div>


          </a>
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('1E') !== -1) {
                  state.splice('1E', 1)
                  return state
                }
                state.push('1E')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('1E') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">1E</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('1F') !== -1) {
                  state.splice('1F', 1)
                  return state
                }
                state.push('1F')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('1F') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">1F</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('1G') !== -1) {
                  state.splice('1G', 1)
                  return state
                }
                state.push('1G')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('1G') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">1G</div>
          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('2A') !== -1) {
                  state.splice('2A', 1)
                  return state
                }
                state.push('2A')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('2A') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">2A</div>

          </a>
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('2B') !== -1) {
                  state.splice('2B', 1)
                  return state
                }
                state.push('2B')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('2B') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">2B</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('2C') !== -1) {
                  state.splice('2C', 1)
                  return state
                }
                state.push('2C')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('2C') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">2C</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('2D') !== -1) {
                  state.splice('2D', 1)
                  return state
                }
                state.push('2D')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('2D') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">2D</div>


          </a>
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('2E') !== -1) {
                  state.splice('2E', 1)
                  return state
                }
                state.push('2E')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('2E') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">2E</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('2F') !== -1) {
                  state.splice('2F', 1)
                  return state
                }
                state.push('2F')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('2F') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">2F</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('2G') !== -1) {
                  state.splice('2G', 1)
                  return state
                }
                state.push('2G')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('2G') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">2G</div>
          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('3A') !== -1) {
                  state.splice('3A', 1)
                  return state
                }
                state.push('3A')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('3A') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">3A</div>

          </a>
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('3B') !== -1) {
                  state.splice('3B', 1)
                  return state
                }
                state.push('3B')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('3B') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">3B</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('3C') !== -1) {
                  state.splice('3C', 1)
                  return state
                }
                state.push('3C')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('3C') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">3C</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('3D') !== -1) {
                  state.splice('3D', 1)
                  return state
                }
                state.push('3D')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('3D') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">3D</div>


          </a>
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('3E') !== -1) {
                  state.splice('3E', 1)
                  return state
                }
                state.push('3E')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('3E') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">3E</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('3F') !== -1) {
                  state.splice('3F', 1)
                  return state
                }
                state.push('3F')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('3F') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">3F</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('3G') !== -1) {
                  state.splice('3G', 1)
                  return state
                }
                state.push('3G')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('3G') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">3G</div>
          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('4A') !== -1) {
                  state.splice('4A', 1)
                  return state
                }
                state.push('4A')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('4A') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">4A</div>

          </a>
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('4B') !== -1) {
                  state.splice('4B', 1)
                  return state
                }
                state.push('4B')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('4B') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">4B</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('4C') !== -1) {
                  state.splice('4C', 1)
                  return state
                }
                state.push('4C')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('4C') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">4C</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('4D') !== -1) {
                  state.splice('4D', 1)
                  return state
                }
                state.push('4D')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('4D') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">4D</div>


          </a>
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('4E') !== -1) {
                  state.splice('4E', 1)
                  return state
                }
                state.push('4E')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('4E') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">4E</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('4F') !== -1) {
                  state.splice('4F', 1)
                  return state
                }
                state.push('4F')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('4F') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">4F</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('4G') !== -1) {
                  state.splice('4G', 1)
                  return state
                }
                state.push('4G')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('4G') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">4G</div>
          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('5A') !== -1) {
                  state.splice('5A', 1)
                  return state
                }
                state.push('5A')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('5A') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">5A</div>

          </a>
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('5B') !== -1) {
                  state.splice('5B', 1)
                  return state
                }
                state.push('5B')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('5B') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">5B</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('5C') !== -1) {
                  state.splice('5C', 1)
                  return state
                }
                state.push('5C')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('5C') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">5C</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('5D') !== -1) {
                  state.splice('5D', 1)
                  return state
                }
                state.push('5D')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('5D') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">5D</div>


          </a>
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('5E') !== -1) {
                  state.splice('5E', 1)
                  return state
                }
                state.push('5E')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('5E') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">5E</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('5F') !== -1) {
                  state.splice('5F', 1)
                  return state
                }
                state.push('5F')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('5F') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">5F</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('5G') !== -1) {
                  state.splice('5G', 1)
                  return state
                }
                state.push('5G')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('5G') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">5G</div>
          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('6A') !== -1) {
                  state.splice('6A', 1)
                  return state
                }
                state.push('6A')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('6A') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">6A</div>

          </a>
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('6B') !== -1) {
                  state.splice('6B', 1)
                  return state
                }
                state.push('6B')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('6B') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">6B</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('6C') !== -1) {
                  state.splice('6C', 1)
                  return state
                }
                state.push('6C')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('6C') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">6C</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('6D') !== -1) {
                  state.splice('6D', 1)
                  return state
                }
                state.push('6D')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('6D') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">6D</div>


          </a>
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('6E') !== -1) {
                  state.splice('6E', 1)
                  return state
                }
                state.push('6E')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('6E') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">6E</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('6F') !== -1) {
                  state.splice('6F', 1)
                  return state
                }
                state.push('6F')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('6F') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">6F</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setSelectedClasses((state) => {
                if (selectedClasses.indexOf('6G') !== -1) {
                  state.splice('6G', 1)
                  return state
                }
                state.push('6G')
                return state
              })
              forceUpdate()
            }}
            className={`${selectedClasses.indexOf('6G') !== -1 ? 'active' : ''} m-1 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="font-weight-bold">6G</div>
          </a>

        </Grid>
      </div>
      <br></br>
      <br></br>

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        Organization/ Committee/ Department (Your name will be shown if this question is blank.)
      </h4>
      <ExampleWrapperSimple>
        <TextField
          className="my-3"
          fullWidth
          id="filled-textarea"
          label=""
          placeholder=""
          multiline
          rowsMax="4"
          onChange={(e) => {
            setCommittee(e.target.value)
          }}
        />
      </ExampleWrapperSimple>

      <br></br>
      <br></br>

      {/* <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        Files upload (PDF/PNG/JPG only)(optional)
      <FileUpload></FileUpload>
      </h4>
      <br></br>
      <br></br> */}

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        Link(s)(optional)
      </h4>
      <ExampleWrapperSimple>
        <TextField
          className="my-3"
          style={{width: '80%'}}
          id="filled-textarea"
          label=""
          placeholder=""
          onChange={e => setTempLink(e.target.value)}
          rowsMax="4"
        />
        <Button onClick={() => {
          setLinks((s) => {
            s.push(tempLink)
            console.log(s)
            return s
          })
          forceUpdate()
        }}>+ Press to add link</Button>
        {links.map(e => {
          return <p>{e} <Button onClick={()=>{
            setLinks((s) => {
              s.splice(s.indexOf(e),1)
              console.log(s)
              return s
            })
            forceUpdate()
          }}>- Remove link</Button></p>
        })}
      </ExampleWrapperSimple>

      <ExampleWrapperSimple>
        For All&nbsp;
        <Switch
        checked={forTeachersOnly}
        onChange={(e) => setForTeachersOnly(e.target.checked)}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
          className="switch-medium"
        ></Switch> Teachers only
      </ExampleWrapperSimple>

      <br></br>
      <br></br>
      
        <div className="text-center mb-4">
          <Button className="btn-primary text-uppercase font-weight-bold font-size-sm my-3"
            onClick={
              async () => {
                const url = `https://script.google.com/macros/s/AKfycbwVVQgS9OmYUuuVZj-_amhcgoqExRanNcpj-mtKdYvh8r1eMHF9R1hV04wqHFAmEnfk/exec?action=insert&table=test&data={"editor": "${user.user.email || '-'}","committee": "${committee || '-'}","title": "${title}","to": "${selectedClasses.join(',')}","start_posting":"${startDate.toDateString()}","stop_posting": "${stopDate.toDateString()}","attachments": "${links.join(',|') || '-'}","approved":"-","content":"${content.replace('\n','\\n')}","priority":1, "for": "${forTeachersOnly? 'teacher':'student,teacher'}"}`
                console.log(url) 
                await axios.get(url)
                alert('Announcement Post Successful')
                window.location.href='/Dashboard'
              }
            }>
            Submit
        </Button>
        </div>
      
    </>
  );
}
