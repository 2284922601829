import app from 'firebase/app';
import 'firebase/database';
const config = {
  apiKey: "AIzaSyAygYa6wipBh5xxkq3xa5b1XvBxxLCtyuQ",
  authDomain: "pfa-plaza.firebaseapp.com",
  databaseURL: "https://morninglsc.firebaseio.com",
  projectId: "pfa-plaza",
  storageBucket: "pfa-plaza.appspot.com",
  messagingSenderId: "352749161886",
  appId: "1:352749161886:web:0e9308f0c78d5a147dda8f",
  measurementId: "G-ZDCX26Q8Y0"
};
class Firebase {
  constructor() {
    app.initializeApp(config);
    this.db = app.database();
  }
  user = uid => this.db.ref(`users/${uid}`);
}

export default Firebase;
