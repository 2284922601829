import React from 'react';
import { PageTitle } from 'layout-components';
import avatar7 from '../../assets/images/lscLogo.png';
import { Grid, Card, CardContent, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import stock1 from '../../assets/images/stock-photos/stock-5.jpg';

import WhatsNew from './component/whats-new';
import BadgeCarousels from './component/badge-carousels';
import Lessons from './component/lessons';
import Tasks from './component/tasks';
import WaitingApproval from './component/waiting-approve';

import DashboardCommerce3 from '../../example-components/DashboardCommerce/DashboardCommerce3';
import DashboardCommerce4 from '../../example-components/DashboardCommerce/DashboardCommerce4';
import DashboardCommerce5 from '../../example-components/DashboardCommerce/DashboardCommerce5';
import DashboardCommerce6 from '../../example-components/DashboardCommerce/DashboardCommerce6';
export default function DashboardCommerce() {
  const data = JSON.parse(localStorage.getItem('currentAnnouncement'))
  return (
    <>
      <PageTitle
        titleHeading={data['Announcement title']}
        titleDescription={new Date(data['Start Posting on...(M/D/YYYY)']).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
        children={
          <Button className="m-2 btn-neutral-primany">
          
            <div className="avatar-icon-wrapper d-30 mr-2">
              <div className="avatar-icon d-30 rounded-circle">
                <img alt="..." src={avatar7} />
              </div>
            </div>
              {data['Post on behalf of...(Committee)']}
          
          </Button>
        }
      />
      <Card className="p-5 mb-spacing-6-x2">
        <Grid item xl={6}>
          <CardContent className="px-0 px-xl-4 pt-4 pb-xl-4">

            <p className="text-black-50 font-size-lg">
              {data['Content']}
            </p>

            <br></br>
            <div className="d-flex mt-4  align-items-center">
              {
                data['Attached Links'] !== '-' ?data['Attached Links'].split(',|').map(
                  link => {
                    const link_t = link.split('//')
                    const link_ts = link_t[link_t.length-1]
                    return <><Button size="small" className="btn-neutral-first"
                    onClick={
                      () => {
                        window.open(link)
                      }
                    }
                    >
                    <FontAwesomeIcon
                      icon={['fas', 'paperclip']} />
                    &nbsp;
                    <div>
                      {link_ts.slice(0,20)}...
                    </div>
                  </Button>
                  &nbsp;
                  &nbsp;</>
                  }
                ) : <></>
              }
              

            </div>
            <br></br>
          </CardContent>

        </Grid>
        <div className="d-flex mt-4 justify-content-end align-items-center">
          <div>
            <Button className="btn-pill btn-gradient shadow-none btn-gradient-inverse bg-deep-blue" onClick={() => window.history.back()} >Back</Button>
          </div>
        </div>

      </Card>


    </>
  );
}
