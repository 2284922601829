import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Card, CardContent, Button } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Trend from 'react-trend';
import CountUp from 'react-countup';

export default function LivePreviewExample() {
  return (
    <>
      <Card style={{ backgroundColor: 'transparent' }} className="card-box mb-spacing-6-x2">

        <div className="card-header" style={{ backgroundColor: 'transparent' }}>
          <div className="card-header--title" style={{ backgroundColor: 'transparent' }}>
            <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
              What's New
            </h4>
          </div>

          <div className="card-header--actions" style={{ backgroundColor: 'transparent' }}>
            <div>
              <Button size="small" className="btn-neutral-primary">
                See all
              </Button>
            </div>
          </div>
        </div>


        <CardContent style={{ backgroundColor: 'transparent' }} className="p-0">
          <div className="table-responsive-md">
            <Table className="table table-borderless table-hover table-striped text-nowrap mb-0">
              <thead>
                <tr>
                  <th style={{ width: '40vw' }} className="text-left" > Announcement   </th>
                  <th className="text-left" >Post date</th>
                  <th className="text-center" >Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td >
                    <div>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="font-weight-bold text-black"
                        title="...">
                        Form Teacher Period Committee
                    </a>
                      <span className="text-black-50 d-block">
                        2020-21 st Term Form Teacher Lesson Evaluation
                    </span>
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">25 Jan 2021</span>
                  </td>

                  <td className="text-center">
                    <div className="badge badge-danger">unread</div>
                  </td>
                </tr>
                <tr className="divider"></tr>

                <tr>
                  <td >
                    <div>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="font-weight-bold text-black"
                        title="...">
                        Student Association
                    </a>
                      <span className="text-black-50 d-block">
                        2020-21 Talent Quest Final Ticket Selling
                    </span>
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">25 Jan 2021</span>
                  </td>

                  <td className="text-center">
                    <div className="badge badge-success">read</div>
                  </td>
                </tr>
                <tr className="divider"></tr>

                <tr>
                  <td >
                    <div>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="font-weight-bold text-black"
                        title="...">
                        1C Form Teacher
                    </a>
                      <span className="text-black-50 d-block">
                        Hand in your One-Page Profile
                    </span>
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">3 Jan 2021</span>
                  </td>

                  <td className="text-center">
                    <div className=" text-warning badge badge-neutral-warning">recall</div>
                  </td>
                </tr>
                <tr className="divider"></tr>
              </tbody>
            </Table>
          </div>
          <div className="p-3 d-flex justify-content-center">
            <Pagination className="pagination-primary" count={10} />
          </div>
        </CardContent>
      </Card>
    </>
  );
}
