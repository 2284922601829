import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Card, CardContent, Button } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Trend from 'react-trend';
import CountUp from 'react-countup';

export default function LivePreviewExample() {
  return (
    <>
      <Card style={{ backgroundColor: 'transparent' }} className="mb-spacing-6-x2">

        <div className="card-header" style={{ backgroundColor: 'transparent' }}>
          <div className="card-header--title" style={{ backgroundColor: 'transparent' }}>
            <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
              Tasks (Student)
            </h4>
          </div>
        </div>


        <CardContent style={{ backgroundColor: 'transparent' }} className="p-0">
          <div className="table-responsive-md ml-4">
            <Table className="table table-borderless table-hover table-striped text-nowrap mb-0">
              <thead>
                <tr>
                  <th style={{ width: '40vw' }} className="text-left" > </th>
                  <th className="text-left" >Due date</th>
                  <th className="text-center" >Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td >
                    <div>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="font-weight-bold text-black"
                        title="...">
                        Form Teacher Period Lesson
                    </a>
                      <span className="text-black-50 d-block">
                      F.1 "Out of the Dark" Lesson 1 Reflection
                    </span>
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">25 Dec 2020</span>
                  </td>

                  <td className="text-center">
                    <div className="badge badge-danger">incomplete</div>
                  </td>
                </tr>
                <tr className="divider"></tr>

                <tr>
                  <td >
                    <div>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="font-weight-bold text-black"
                        title="...">
                        Guidance Committee
                    </a>
                      <span className="text-black-50 d-block">
                      Mental health survey (2020-2021) 
                    </span>
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">25 Jan 2021</span>
                  </td>

                  <td className="text-center">
                    <div className="badge badge-success">completed</div>
                  </td>
                </tr>
                <tr className="divider"></tr>

                <tr>
                  <td >
                    <div>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="font-weight-bold text-black"
                        title="...">
                        Form Teachers' Interview
                    </a>
                      <span className="text-black-50 d-block">
                        One-Page Profile (Term 1 1st Draft)
                    </span>
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">3 Jan 2021</span>
                  </td>

                  <td className="text-center">
                    <div className=" badge badge-warning">re-submit</div>
                  </td>
                </tr>
                <tr className="divider"></tr>
              </tbody>
            </Table>
          </div>
          <div className="p-3 d-flex justify-content-center">
            <Pagination className="pagination-primary" count={10} />
          </div>
        </CardContent>
      </Card>
    </>
  );
}
