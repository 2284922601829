import React from 'react';
import axios from 'axios'
import { Table, Card, CardContent, Button } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import UserContext from 'providers/UserContext';

function LivePreviewExample(props) {
  const user = React.useContext(UserContext)
  const [news, setNews] = React.useState(
    []
  )
  React.useEffect(
    async () => {
    //https://script.google.com/macros/s/AKfycbzepzPz9FHeiUEjjHR4vEP6qU_MBnjfyJcNywlAx1RpJf1WZsmpRftFwg/exec?action=read&table=Sheet1
    const data = await axios.get('https://lsc-secure.rubbity.io/https://script.google.com/macros/s/AKfycbzepzPz9FHeiUEjjHR4vEP6qU_MBnjfyJcNywlAx1RpJf1WZsmpRftFwg/exec?action=read&table=Sheet1')
    setNews(data.data.data)
  },[])
  return (
    <>
      <Card style={{ backgroundColor: 'transparent' }} className="mb-spacing-6-x2">

        <div className="card-header" style={{ backgroundColor: 'transparent' }}>
          <div className="card-header--title" style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: 'space-between' }}>
            <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
              What's New
            </h4>
            
              {user.userProps.role!=='student'? <Button size="small" className="btn-neutral-primary " onClick={
                () => {
          
                  window.location.href = 'AnnouncementPostForm'
                }
              }>Post announcement</Button>: <></>}
            
          </div>
        </div>


        <CardContent style={{ backgroundColor: 'transparent', overflowX:'scroll' }} className="p-0">
          <div className="table-responsive-md ml-4">
            <Table className="table table-borderless table-hover table-striped text-nowrap mb-0">
              <thead>
                <tr>
                  <th className="text-left" >Title </th>
                  <th  className="text-left" >Committee </th>
                  <th className="text-left" >Post date</th>

                </tr>
              </thead>
              <tbody>

              {news.sort((e1,e2) => 
                new Date(e2['Start Posting on...(M/D/YYYY)']) - new Date(e1['Start Posting on...(M/D/YYYY)'])
              ).filter(e => (e['To...'].replace(/ /g,'').split(',').indexOf(user.userProps.Class) !== -1 &&
              e['For'].replace(/ /g,'').split(',').indexOf(user.userProps.role) !== -1 
              )||
              e['editor'] === user.user.email).map(e => <><tr>
                  <td width={'50%'}>
                    
                      <a
                        href="AnnouncementPage"
                        onClick={(eh) => {
                          eh.preventDefault()
                          localStorage.setItem('currentAnnouncement', JSON.stringify(e))
                          window.location.href = 'AnnouncementPage'
                        }}
                        className="font-weight-bold text-black"
                        title="...">
                        {e['Announcement title']}
                    </a>
                    </td><td width={'20%'}>
                      <span className="text-black-50 d-block">
                        {e['Post on behalf of...(Committee)']}
                    </span>
                    
                  </td>
                  <td className="text-left" width={'30%'}>
                    <span className="font-weight-bold text-primary">{new Date(e['Start Posting on...(M/D/YYYY)']).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</span>
                  </td>


                </tr>
                <tr className="divider"/></>)}


                {/*<tr>*/}
                {/*  <td >*/}
                {/*    <div>*/}
                {/*      <a*/}
                {/*        href="#/"*/}
                {/*        onClick={(e) => e.preventDefault()}*/}
                {/*        className="font-weight-bold text-black"*/}
                {/*        title="...">*/}
                {/*        1C Form Teacher*/}
                {/*    </a>*/}
                {/*      <span className="text-black-50 d-block">*/}
                {/*        Hand in your One-Page Profile*/}
                {/*    </span>*/}
                {/*    </div>*/}
                {/*  </td>*/}
                {/*  <td className="text-left">*/}
                {/*    <span className="font-weight-bold text-primary">3 Jan 2021</span>*/}
                {/*  </td>*/}

                {/*  <td className="text-center">*/}
                {/*    <div className=" badge badge-warning">recall</div>*/}
                {/*  </td>*/}
                {/*</tr>*/}
                {/*<tr className="divider"></tr>*/}
              </tbody>
            </Table>
          </div>
          <div className="p-3 d-flex justify-content-center">
            <Pagination className="pagination-primary" count={1} />
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default LivePreviewExample
