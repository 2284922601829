import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Card, CardContent, Button } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Trend from 'react-trend';
import CountUp from 'react-countup';
import axios from 'axios';
import UserContext from 'providers/UserContext';

export default function LivePreviewExample() {
  const user = React.useContext(UserContext)
  const [lessons, setLessons] = React.useState(
    []
  )
  React.useEffect(async () => {
    const data = await axios.get('https://lsc-secure.rubbity.io/https://script.google.com/macros/s/AKfycbweqM6_-Ub9q1WOnS0qld3de7denAnCJ_1Ch0mDRtTnEJnFSTdOiXcZQg/exec?action=read&table=Sheet1')
    setLessons(data.data.data)
    //
  }, [])
  return (
    <>
      <Card style={{ background: 'transparent' }} className=" mb-spacing-6-x2">

        <div className="card-header" style={{ backgroundColor: 'transparent' }}>
          <div className="card-header--title" style={{ backgroundColor: 'transparent' }}>
            <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
              Form Teacher Period Lessons
            </h4>
          </div>

        </div>


        <CardContent style={{ background: 'transparent' }} className="p-0">
          <div className="table-responsive-md ml-4">
            <Table className="table table-borderless table-hover table-striped text-nowrap mb-0" >
              <thead>
                <tr>
                  <th style={{ width: '40vw' }} className="text-left" > </th>
                  <th className="text-center" >Status</th>
                </tr>
              </thead>
              <tbody>
              {lessons.filter(e => e['To'].replace(/\s/g, '').split(',').indexOf(user.userProps.Class) !== -1 && 
              e['For'].replace(/ /g,'').split(',').indexOf(user.userProps.role) !== -1).map(e =><><tr>
                  <td >
                    <div>
                      <a
                        href={e['Link']}
                        // onClick={(e) => e.preventDefault()}
                        target="_blank"
                        className="font-weight-bold text-black"
                        title="...">
                        {e['Topic']}
                    </a>
                      <span className="text-black-50 d-block">
                        {e['Lesson']}
                    </span>
                    </div>
                  </td>
                  

                  <td className="text-center">
                    {
                      {
                        "IN PROGRESS": <div className="badge badge-neutral-first">In Progress</div>,
                        "COMPLETED": <div className="badge badge-neutral-success">Completed</div>,
                        "NEW": <div className=" text-warning badge badge-neutral-warning">New</div>
                      }[e['Status']]
                    }
                  </td>
                </tr>
                <tr className="divider"></tr></>)}
                {/*<tr>*/}
                {/*  <td >*/}
                {/*    <div>*/}
                {/*      <a*/}
                {/*        href="#/"*/}
                {/*        onClick={(e) => e.preventDefault()}*/}
                {/*        className="font-weight-bold text-black"*/}
                {/*        title="...">*/}
                {/*        One page profile*/}
                {/*    </a>*/}
                {/*      <span className="text-black-50 d-block">*/}
                {/*        Guide to fill in the profile*/}
                {/*    </span>*/}
                {/*    </div>*/}
                {/*  </td>*/}
                {/* */}

                {/*  <td className="text-center">*/}
                {/*    <div className="badge badge-neutral-success">Completed</div>*/}
                {/*  </td>*/}
                {/*</tr>*/}
                {/*<tr className="divider"></tr>*/}
                {/*<tr>*/}
                {/*  <td >*/}
                {/*    <div>*/}
                {/*      <a*/}
                {/*        href="#/"*/}
                {/*        onClick={(e) => e.preventDefault()}*/}
                {/*        className="font-weight-bold text-black"*/}
                {/*        title="...">*/}
                {/*        F.1 "Out of the Dark"*/}
                {/*    </a>*/}
                {/*      <span className="text-black-50 d-block">*/}
                {/*        Lessson 2 Cognitive restructuring*/}
                {/*    </span>*/}
                {/*    </div>*/}
                {/*  </td>*/}
                {/*  */}

                {/*  <td className="text-center">*/}
                {/*    <div className=" text-warning badge badge-neutral-warning">New</div>*/}
                {/*  </td>*/}
                {/*</tr>*/}
                {/*<tr className="divider"></tr>*/}
              </tbody>
            </Table>
          </div>
          <div className="p-3 d-flex justify-content-center">
            <Pagination className="pagination-primary" count={Math.ceil(lessons.length / 5)} />
          </div>
        </CardContent>
      </Card>
    </>
  );
}
