import React, { useState } from 'react';
import { Grid, Card, Menu, MenuItem, Button, TextField } from '@material-ui/core';
import { PageTitle } from 'layout-components';
import { NavLink } from 'react-router-dom';
import Script from "react-inline-script";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FirebaseDatabaseMutation, FirebaseDatabaseProvider} from '@react-firebase/database'
import UserContext from '../../providers/UserContext';
import firebase from 'firebase'
import  { FirebaseContext } from 'components/Firebase';

import Snackbar from '@material-ui/core/Snackbar';
import {config} from '../../providers/firebase-config'
export default function MoodTrackerPage() {

  const user = React.useContext(UserContext)
  const [anchorEl, setAnchorEl] = useState(null);
  const [mainEmotion,setMainEmotion] = useState('')
  const [subEmotion, setSubEmotion] = useState('')
  const [step, setStep] = useState(0)
  const [concerns, setConcerns] = useState([])
  const [lastWords, setLastWords] = useState('')
  const firebase = React.useContext(FirebaseContext)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setMainEmotion('Enjoyment')
    setSubEmotion(e.currentTarget.innerText)
    setStep(1)
    setAnchorEl(null);
  };

  const [anchorEla, setAnchorEla] = useState(null);

  const handleClicka = (event) => {
    setAnchorEla(event.currentTarget);
  };

  const handleClosea = (e) => {
    setMainEmotion('Angry')
    setSubEmotion(e.currentTarget.innerText)
    setStep(1)
    setAnchorEla(null);
  };
  const [anchorElb, setAnchorElb] = useState(null);

  const handleClickb = (event) => {
    setAnchorElb(event.currentTarget);
  };

  const handleCloseb = (e) => {
    setMainEmotion('Fear')
    setSubEmotion(e.currentTarget.innerText)
    setStep(1)
    setAnchorElb(null);
    console.log(mainEmotion, subEmotion)
  };
  const [anchorElc, setAnchorElc] = useState(null);

  const handleClickc = (event) => {
    setAnchorElc(event.currentTarget);
  };

  const [, forceUpdate] = React.useReducer(x => x + 1, 0);
  const [snackbarOpen, toggleSnackBar] = React.useReducer(x => !x, false)

  const handleClosec = (e) => {
    setMainEmotion('Disgust')
    setSubEmotion(e.currentTarget.innerText)
    setStep(1)
    setAnchorElc(null);
  };
  const [anchorEld, setAnchorEld] = useState(null);

  const handleClickd = (event) => {
    setAnchorEld(event.currentTarget);
  };

  const [payload, setPayload] = useState({})

  const handleClosed = (e) => {
    setMainEmotion('Sadness')
    setSubEmotion(e.currentTarget.innerText)
    setStep(1)
    setAnchorEld(null);
  };
  const [anchorEle, setAnchorEle] = useState(null);

  const handleClicke = (event) => {
    setStep(1)
    setAnchorEle(event.currentTarget);
  };

  const handleClosee = () => {
    setAnchorEle(null);
  };



  return (
    <>
      <PageTitle
        titleHeading="Morning Lasallian Mood Tracker"
        titleDescription=""
      />
      <div style={{display: step == 0 ? 'block': 'none'}} className="mb-spacing-6-x2">
        <h4 className="font-size-xl mb-0 py-3 font-weight-bold">
          How are you today?
      </h4>
        <Grid container spacing={6}>
          <Grid item md={6} lg={4}>
            <Card className="rounded shadow-xl card-box-hover-rise">
              <div className="p-3 p-lg-4 text-center " >
                <div className="bg-deep-blue text-white font-size-xl d-100 btn-icon card-icon-wrapper rounded-circle">
                  <img src="enjoyment.svg"></img>
                </div>

                <p className="card-text mb-4">

                </p>
                <div className="text-center">
                  <Button
                    aria-controls="simple-menu"
                    className="btn-neutral-success text-uppercase font-size-xl font-weight-bold rounded d-inline-block"
                    onClick={handleClick}
                    fullWidth>
                    Enjoyment
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    classes={{ list: 'p-0' }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    <div className="p-3">
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClose}>
                        Happiness
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClose}>
                        Love
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClose}>
                        Relief
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClose}>
                        Contentment
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClose}>
                        Amusement
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClose}>
                        Joy
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClose}>
                        Pride
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClose}>
                        Excitement
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClose}>
                        Peace
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClose}>
                        Satisfaction
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClose}>
                        Compassion
                      </MenuItem>

                    </div>
                  </Menu>
                </div>
              </div>
            </Card>
          </Grid>

          <Grid item md={6} lg={4}>
            <Card className="rounded shadow-xl card-box-hover-rise">
              <div className="p-3 p-lg-4 text-center " >
                <div className="bg-deep-blue text-white font-size-xl d-100 btn-icon card-icon-wrapper rounded-circle">
                  <img src="angry.svg"></img>
                </div>

                <p className="card-text mb-4">

                </p>
                <div className="text-center">
                  <Button
                    aria-controls="angry-menu"
                    className="btn-neutral-danger text-uppercase font-size-xl font-weight-bold rounded d-inline-block"
                    onClick={handleClicka}
                    fullWidth>
                    Angry
                  </Button>
                  <Menu
                    id="angry-menu"
                    anchorEl={anchorEla}
                    keepMounted
                    classes={{ list: 'p-0' }}
                    open={Boolean(anchorEla)}
                    onClose={handleClosea}>
                    <div className="p-3">
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosea}>
                        Annoyed
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosea}>
                        Frustrated
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosea}>
                        Peeved
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosea}>
                        Contrary
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosea}>
                        Bitter
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosea}>
                        Infuriated
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosea}>
                        Irritated
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosea}>
                        Mad
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosea}>
                        Cheated
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosea}>
                        Vengeful
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosea}>
                        Insulted
                      </MenuItem>
                    </div>
                  </Menu>

                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} lg={4}>
            <Card className="rounded shadow-xl card-box-hover-rise">
              <div className="p-3 p-lg-4 text-center " >
                <div className="bg-deep-blue text-white font-size-xl d-100 btn-icon card-icon-wrapper rounded-circle">
                  <img src="fear.svg"></img>
                </div>
                <p className="card-text mb-4">

                </p>
                <div className="text-center">
                  <Button
                    aria-controls="fear-menu"
                    className="btn-neutral-dark text-uppercase font-size-xl font-weight-bold rounded d-inline-block"
                    onClick={handleClickb}
                    fullWidth>
                    Fear
                  </Button>
                  <Menu
                    id="fear-menu"
                    anchorEl={anchorElb}
                    keepMounted
                    classes={{ list: 'p-0' }}
                    open={Boolean(anchorElb)}
                    onClose={handleCloseb}>
                    <div className="p-3">
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleCloseb}>
                        Worried
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleCloseb}>
                        Doubtful
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleCloseb}>
                        Nervous
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleCloseb}>
                        Anxious
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleCloseb}>
                        Terrified
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleCloseb}>
                        Panicked
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleCloseb}>
                        Horrified
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleCloseb}>
                        Desperate
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleCloseb}>
                        Confused
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleCloseb}>
                        Stressed
                      </MenuItem>
                    </div>
                  </Menu>

                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} lg={4}>
            <Card className="rounded shadow-xl card-box-hover-rise">
              <div className="p-3 p-lg-4 text-center " >
                <div className="bg-deep-blue text-white font-size-xl d-100 btn-icon card-icon-wrapper rounded-circle">
                  <img src="disgust.svg"></img>
                </div>
                <p className="card-text mb-4">

                </p>
                <div className="text-center">
                  <Button
                    aria-controls="disgust-menu"
                    className="btn-neutral-primary text-uppercase font-size-xl font-weight-bold rounded d-inline-block"
                    onClick={handleClickc}
                    fullWidth>
                    Disgust
                  </Button>
                  <Menu
                    id="disgust-menu"
                    anchorEl={anchorElc}
                    keepMounted
                    classes={{ list: 'p-0' }}
                    open={Boolean(anchorElc)}
                    onClose={handleClosec}>
                    <div className="p-3">
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosec}>
                        Dislike
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosec}>
                        Revulsion
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosec}>
                        Loathing
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosec}>
                        Disapproving
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosec}>
                        Offended
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosec}>
                        Horrified
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosec}>
                        Uncomfortable
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosec}>
                        Nauseated
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosec}>
                        Disturbed
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosec}>
                        Withdrawal
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosec}>
                        Aversion
                      </MenuItem>
                    </div>
                  </Menu>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} lg={4}>
            <Card className="rounded shadow-xl card-box-hover-rise">
              <div className="p-3 p-lg-4 text-center " >
                <div className="bg-deep-blue text-white font-size-xl d-100 btn-icon card-icon-wrapper rounded-circle">
                  <img src="sad.svg"></img>
                </div>

                <p className="card-text mb-4">

                </p>
                <div className="text-center">
                  <Button
                    aria-controls="sad-menu"
                    className="btn-neutral-info text-uppercase font-size-xl font-weight-bold rounded d-inline-block"
                    onClick={handleClickd}
                    fullWidth>
                    Sadness
                  </Button>
                  <Menu
                    id="disgust-menu"
                    anchorEl={anchorEld}
                    keepMounted
                    classes={{ list: 'p-0' }}
                    open={Boolean(anchorEld)}
                    onClose={handleClosed}>
                    <div className="p-3">
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosed}>
                        Lonely
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosed}>
                        Heartbroken
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosed}>
                        Gloomy
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosed}>
                        Disappointed
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosed}>
                        Hopeless
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosed}>
                        Grieved
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosed}>
                        Unhappy
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosed}>
                        Lost
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosed}>
                        Troubled
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosed}>
                        Resigned
                      </MenuItem>
                      <MenuItem className="pr-5 px-3 text-primary" onClick={handleClosed}>
                        Miserable
                      </MenuItem>
                    </div>
                  </Menu>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} lg={4}>
            <Card className="rounded shadow-xl card-box-hover-rise">
              <div className="p-3 p-lg-4 text-center " >
                {/* <div className="bg-deep-blue text-white font-size-xl d-100 btn-icon card-icon-wrapper rounded-circle">
                  <img src="enjoyment.svg"></img>
                </div> */}
                <h3 className="font-weight-bold display-5 mt-4 mb-3">
                  Other:
                </h3>
                <TextField
                  className="py-4 font-size-xl font-weight-bold "
                  fullWidth
                  id="filled-textarea"
                  label=""
                  onChange={e=>{
                    e.persist()
                    setMainEmotion(state => e.target.value)
                  }}
                  placeholder="Write it here..."
                />
                <p className="card-text mb-4">

                </p>
                <div className="text-center">
                  <Button
                    className="btn-outline-primary text-uppercase font-size-xl font-weight-bold rounded d-inline-block"
                    onClick={handleClicke}
                    fullWidth>
                    go
                  </Button>
                </div>
              </div>
            </Card>
          </Grid>

        </Grid>
      </div>

      <div  style={{display: step == 1 ? 'block': 'none'}}  className="mb-spacing-6-x2">
        <h4 className="font-size-xl mb-0 py-3 font-weight-bold">
          I see. Do you want to talk about that?
        </h4>
        <Grid container spacing={8}>
          <Grid item md={6} lg={6}>
            <a
              
              onClick={(e) => {
                setStep(2)
              }}>
              <Card className="rounded shadow-xl card-box-hover-rise">
                <div className="p-3 p-lg-4 text-center " >
                  <div className="bg-deep-blue text-white font-size-xl d-100 btn-icon card-icon-wrapper rounded-circle">
                    <img src="smile-1.svg"></img>
                  </div>
                  <h3 className="font-weight-bold display-5 mt-4 mb-3">
                    Yes
                </h3>
                </div>
              </Card>
            </a>
          </Grid>
          <Grid item md={6} lg={6}>
            <a
              
              onClick={(e) => {
                setPayload({ 
                  lastWords,
                  concerns,
                  mainEmotion,
                  subEmotion,
                  time: Date.now()
                 })
                setStep(4)
              }}>
              <Card className="rounded shadow-xl card-box-hover-rise">
                <div className="p-3 p-lg-4 text-center " >
                  <div className="bg-deep-blue text-white font-size-xl d-100 btn-icon card-icon-wrapper rounded-circle">
                    <img src="shut.svg"></img>
                  </div>
                  <h3 className="font-weight-bold display-5 mt-4 mb-3">
                    No
                </h3>
                </div>
              </Card>
            </a>
          </Grid>
        </Grid>
      </div>

      <div  style={{display: step == 2 ? 'block': 'none'}}  className="mb-spacing-6-x2">
        <h4 className="font-size-xl mb-0 py-3 font-weight-bold">
          What is it about?
        </h4>
        <div className="d-flex justify-content-center">
          <a
            href="#/"
            onClick={(e) => {e.preventDefault()
            setConcerns(h => {
              let ind = h.indexOf('Family')
              if(ind !== -1)h.splice(ind, 1)
              else h.push('Family')
              console.log(h)
              return h
            })
            forceUpdate()
            }}
            className={`${concerns.indexOf('Family')!==-1? 'active': ''} m-3 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="d-10 text-white d-flex align-items-center justify-content-center ">
              <img style={{ width: '30%' }} src="family.svg"></img>
            </div>
            <div className="font-weight-bold mt-2">Family</div>

          </a>
          <a
            href="#/"
            onClick={(e) => {
              e.preventDefault()
              setConcerns(h => {
                let ind = h.indexOf('Academic')
                if(ind !== -1)h.splice(ind, 1)
                else h.push('Academic')
                return h
              })
              forceUpdate()
            }}
            className={`${concerns.indexOf('Academic')!==-1? 'active': ''} m-3 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="d-10 text-white d-flex align-items-center justify-content-center">
              <img style={{ width: '30%' }} src="academic.svg"></img>
            </div>
            <div className="font-weight-bold mt-2">Academic</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {e.preventDefault()
              setConcerns(h => {
                let ind = h.indexOf('Friends')
                if(ind !== -1)h.splice(ind, 1)
                else h.push('Friends')
                
                return h
              })
              forceUpdate()}}
            className={`${concerns.indexOf('Friends')!==-1? 'active': ''} m-3 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="d-10 text-white d-flex align-items-center justify-content-center">
              <img style={{ width: '30%' }} src="friend.svg"></img>
            </div>
            <div className="font-weight-bold mt-2">Friends</div>

          </a>
        </div>
        <div className="d-flex justify-content-center">
          <a
            href="#/"
            onClick={(e) =>{ e.preventDefault()
              setConcerns(h => {
                let ind = h.indexOf('Health')
                if(ind !== -1)h.splice(ind, 1)
                else h.push('Health')
                
                return h
              })
              forceUpdate()
            }}
            className={`${concerns.indexOf('Health')!==-1? 'active': ''} m-3 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="d-10 text-white d-flex align-items-center justify-content-center">
              <img style={{ width: '30%' }} src="health.svg"></img>
            </div>
            <div className="font-weight-bold mt-2">Health</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {e.preventDefault()
              setConcerns(h => {
                let ind = h.indexOf('Finance')
                if(ind !== -1)h.splice(ind, 1)
                else h.push('Finance')
                
                return h
              })
              forceUpdate()
            }}
            className={`${concerns.indexOf('Finance')!==-1? 'active': ''} m-3 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="d-10 text-white d-flex align-items-center justify-content-center">
              <img style={{ width: '30%' }} src="finance.svg"></img>
            </div>
            <div className="font-weight-bold mt-2">Finance</div>

          </a>

          <a
            href="#/"
            onClick={(e) => {e.preventDefault()
              setConcerns(h => {
                let ind = h.indexOf('Love')
                if(ind !== -1)h.splice(ind, 1)
                else h.push('Love')
                
                return h
              })
              forceUpdate()
            }}
            className={`${concerns.indexOf('Love')!==-1? 'active': ''} m-3 btn-input-select`}>
            <div className="selected-icon">
              <FontAwesomeIcon icon={['fas', 'check']} />
            </div>
            <div className="d-10 text-white d-flex align-items-center justify-content-center">
              <img style={{ width: '30%' }} src="love.svg"></img>
            </div>
            <div className="font-weight-bold mt-2">Love</div>
          </a>
        </div>

        <div className="text-center mb-4">
          <Button className="btn-primary text-uppercase font-weight-bold btn-gradient bg-happy-green font-size-lg my-3" onClick={()=>setStep(3)}>
            Go
            </Button>
        </div>

      </div>

      <div  style={{display: step == 3 ? 'block': 'none'}} >
        <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
          What happened?
        </h4>

        <TextField
          className="my-3"
          fullWidth
          id="filled-textarea"
          label=""
          placeholder="Write it down..."
          multiline
          rowsMax="15"
          onChange={e=>{
            console.log({ 
              lastWords,
              concerns,
              mainEmotion,
              subEmotion,
              time: Date.now()
             })
            e.persist()
            setLastWords(state => e.target.value)
            console.log(lastWords)
          }}
        />

        <div className="text-center mb-4">
          <Button className="btn-primary text-uppercase btn-gradient bg-happy-green font-weight-bold font-size-lg my-3"
          onClick={()=> {
            setPayload({ 
              lastWords,
              concerns,
              mainEmotion,
              subEmotion,
              time: Date.now()
             })
            setStep(4)}}>
            Go
          </Button>
        </div>

      </div>

      <div  style={{display: step == 4 ? 'block': 'none'}} >
        <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
          Confirm submit?
      </h4>
      <div className="d-flex align-items-center justify-content-center"> 
      <p>
          <b>Main Emotion:</b> {mainEmotion}<br></br>
          <b>Sub Emotion:</b> {subEmotion}<br>
          </br>
          <b>Areas of interest:</b> {concerns.join(', ')} <br></br>
          <b> What happened?</b> {lastWords}

        </p>
      </div>
        <div className="d-flex align-items-center justify-content-center"> 
       
   
<Button className="m-2 btn-primary btn-gradient bg-happy-green font-size-lg my-3"
disabled={snackbarOpen}
onClick={async () => {
  console.log(payload)
  await firebase.db.ref('mood/'+(user.userProps.email || '').split('@')[0]+'/').push(payload)
  toggleSnackBar()
  window.location.href = 'MoodChart'
}}>
   {console.log(lastWords)}
   
  Yes, Go Ahead
</Button>
  
          
          <Button className="m-2 btn-primary btn-gradient bg-strong-bliss font-size-lg my-3" 
          disabled={snackbarOpen}
          onClick={()=> {
            setStep(0)
          }}>
            No, I'll Re-do it
        </Button>
        </div>
      </div>



      <div  style={{display: step == 5 ? 'block': 'none'}} >
        <img style={{ width: '100%' }} src="thank you.png"></img>
        <NavLink
          to="/dashboard">
          <div className="text-center mb-4">
            <Button className="m-2 btn-primary btn-gradient bg-happy-green font-size-lg my-3">
              Okay
              </Button>
          </div>
        </NavLink>
      </div>
      <Snackbar
          anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
          key={`bottom,right`}
          open={snackbarOpen}
          classes={{ root: 'toastr-success' }}
          onClose={toggleSnackBar}
          message={'Successfully saved your mood!!'}
        />



      {/* <Script src="http://googleformrestyler.apixml.net/GoogleFormStyler.js"
        form="https://docs.google.com/forms/d/e/1FAIpQLSeqyXMULHvsHCv5MmjMChwzbr8C7JKuG65xdP_s8JPaM87e3Q/viewform?embedded=true">
      </Script>
      <div className="container" id="ff-compose"></div>
      <script async defer src="https://formfacade.com/include/117058093303862256920/form/1FAIpQLSehka67wwXR2FAtdHb38MtqEAt5nJA6uUZc8rWA4q6tAxjLKQ/bootstrap.js?div=ff-compose"></script>
 */}

      {/* <NavLink
        to="/Dashboard">
        <div className="text-center mb-4">
          <Button className="btn-primary text-uppercase font-weight-bold font-size-sm my-3">
            Submit
        </Button>
        </div>
      </NavLink> */}

    </>
  );
}
