import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from '@material-ui/core';

import Slider from 'react-slick';

import login1star from '../../../assets/images/badge/login1starBadge.png';
import login3star from '../../../assets/images/badge/login3starBadge.png';
import login5star from '../../../assets/images/badge/login5starBadge.png';
import login10star from '../../../assets/images/badge/login10starBadge.png';


import stock1 from '../../../assets/images/stock-photos/stock-1.jpg';
import stock2 from '../../../assets/images/stock-photos/stock-2.jpg';
import stock3 from '../../../assets/images/stock-photos/stock-3.jpg';
import stock4 from '../../../assets/images/stock-photos/stock-4.jpg';

function SliderArrowNext(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
    </div>
  );
}

function SliderArrowPrev(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <FontAwesomeIcon icon={['fas', 'chevron-left']} />
    </div>
  );
}

export default function LivePreviewExample() {
  const widgetsCarousels4A = {
    dots: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SliderArrowNext />,
    prevArrow: <SliderArrowPrev />,
    responsive: [
      {
        breakpoint: 1450,
        settings: { slidesToShow: 4, slidesToScroll: 1 }
      },
      {
        breakpoint: 1100,
        settings: { slidesToShow: 3, slidesToScroll: 1 }
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 5, slidesToScroll: 1 }
      }
    ]
  };

  return (
    <>
      <Card className="mb-spacing-6-x2" style={{ background: 'transparent' }}>
        <div className="card-header" style={{ backgroundColor: 'transparent' }}>
          <div className="card-header--title" style={{ backgroundColor: 'transparent' }}>
            <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
              Achievements (Student)
            </h4>
            
          </div>
        </div>

        <div style={{width:'80%', margin:'auto'}}>
          <Slider
            className="rounded-lg overflow-hidden slider-arrows-outside slider-dots-outside slider-dots-light"
            {...widgetsCarousels4A}>

            <div>
              <a
                href="#/"
                onClick={(e) => e.preventDefault()}>
                <div>
                  <img style={{ maxHeight: '150px' }} src={login1star} />
                </div>
              </a>
            </div>

            <div>
              <a
                href="#/"
                onClick={(e) => e.preventDefault()}>
                <div>
                  <img style={{ maxHeight: '150px' }} src={login3star} />
                </div>
              </a>
            </div>

            <div>
              <a
                href="#/"
                onClick={(e) => e.preventDefault()}>
                <div>
                  <img style={{ maxHeight: '150px' }} src={login5star} />
                </div>
              </a>
            </div>

            <div>
              <a
                href="#/"
                onClick={(e) => e.preventDefault()}>
                <div>
                  <img style={{ maxHeight: '150px' }} src={login10star} />
                </div>
              </a>
            </div>

            <div>
              <a
                href="#/"
                onClick={(e) => e.preventDefault()}>
                <div>
                  <img style={{ maxHeight: '150px' }} src={login1star} />
                </div>
              </a>
            </div>

            <div>
              <a
                href="#/"
                onClick={(e) => e.preventDefault()}>
                <div>
                  <img style={{ maxHeight: '150px' }} src={login3star} />
                </div>
              </a>
            </div>


          </Slider>
        </div>
      </Card>
    </>
  );
}
