import React from 'react';

import clsx from 'clsx';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

import navihome from '../../assets/images/naviLogo/navihome.png';
import naviaward from '../../assets/images/naviLogo/naviaward.png';
import navicommunity from '../../assets/images/naviLogo/navicommunity.png';
import navigallery from '../../assets/images/naviLogo/navigallery.png';
import navitask from '../../assets/images/naviLogo/navitask.png';

import HeaderDots from '../../layout-components/HeaderDots';
import HeaderDrawer from '../../layout-components/HeaderDrawer';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import HeaderSearch from '../../layout-components/HeaderSearch';
import HeaderMenu from '../../layout-components/HeaderMenu';

const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <div
      className={clsx('app-header', {
        'app-header--shadow': headerShadow,
        'app-header--opacity-bg': headerBgTransparent
      })}>
      <div className="app-header--pane">
        <button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
        {/*<HeaderSearch />
        <HeaderMenu />*/}
      </div>
      <div className="app-header--pane">
        <NavLink
          to="/Dashboard"
          title="Morning Lasallian Dashboard">
          <div className="avatar-icon rounded">
            <img src={navihome} />
          </div>
        </NavLink>
        {/* <NavLink
          to="/TaskPage"
          title="My Tasks">
          <div className="avatar-icon rounded">
            <img src={navitask} />
          </div>
        </NavLink>
        <NavLink
          to="/LeaderboardPage"
          title="The Leaderboard">
          <div className="avatar-icon rounded">
            <img src={naviaward} />
          </div>
        </NavLink>
        <NavLink
          to="/CommunityPage"
          title="Lasallians">
          <div className="avatar-icon rounded">
            <img src={navicommunity} />
          </div>
        </NavLink>
        <NavLink
          to="/GalleryPage"
          title="Gallery">
          <div className="avatar-icon rounded">
            <img src={navigallery} />
          </div>
        </NavLink> */}
        {/*<HeaderDots />
        <HeaderUserbox />
        <HeaderDrawer />*/}

      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
