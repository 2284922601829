import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Card, CardContent, Button, MenuItem, TextField } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Trend from 'react-trend';
import CountUp from 'react-countup';

const actions = [
  {
    value: 'waiting',
    label: '---'
  },
  {
    value: 'approve',
    label: 'Approve'
  },
  {
    value: 'redo',
    label: 'Redo'
  }
];

export default function LivePreviewExample() {
  const [action, setAction] = useState('waiting');

  const handleChange = (event) => {
    setAction(event.target.value);
  };

  return (
    <>
      <Card style={{ backgroundColor: 'transparent' }} className="mb-spacing-6-x2">

        <div className="card-header" style={{ backgroundColor: 'transparent' }}>
          <div className="card-header--title" style={{ backgroundColor: 'transparent' }}>
            <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
              Waiting Approval (Teacher)
            </h4>
          </div>
        </div>


        <CardContent style={{ backgroundColor: 'transparent' }} className="p-0">
          <div className="table-responsive-md ml-4">
            <Table className="table table-borderless table-hover table-striped text-nowrap mb-0">
              <thead>
                <tr>
                  <th style={{ width: '40vw' }} className="text-left" > </th>
                  <th className="text-left" >Submit date</th>
                  <th className="text-center" >Action</th>
                  <th className="text-center" >Save</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td >
                    <div>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="font-weight-bold text-black"
                        title="...">
                        1C (28) Macus Cheng
                    </a>
                      <span className="text-black-50 d-block">
                        Good Deed Record
                    </span>
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">25 Dec 2020</span>
                  </td>

                  <td className="text-center">
                    <div>
                      <TextField
                        fullWidth
                        className="m-2"
                        id="outlined-select-currency"
                        select
                        value={action}
                        onChange={handleChange}
                        variant="outlined">
                        {actions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </td>

                  <td className="text-center">
                    <Button className="btn-neutral-success mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                      <FontAwesomeIcon icon={['fas', 'check']} className="font-size-sm" />
                    </Button>
                  </td>



                </tr>
                <tr className="divider"></tr>

                <tr>
                  <td >
                    <div>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="font-weight-bold text-black"
                        title="...">
                        1D (13) Vincent Ma
                    </a>
                      <span className="text-black-50 d-block">
                        SSR Record Form
                    </span>
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">25 Jan 2021</span>
                  </td>

                  <td className="text-center">
                    <div>
                      <TextField
                        fullWidth
                        className="m-2"
                        id="outlined-select-currency"
                        select
                        value={action}
                        onChange={handleChange}
                        variant="outlined">
                        {actions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </td>

                  <td className="text-center">
                    <Button className="btn-neutral-success mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                      <FontAwesomeIcon icon={['fas', 'check']} className="font-size-sm" />
                    </Button>
                  </td>
                </tr>
                <tr className="divider"></tr>

                <tr>
                  <td >
                    <div>
                      <a
                        href="#/"
                        onClick={(e) => e.preventDefault()}
                        className="font-weight-bold text-black"
                        title="...">
                        1C (5) Harold Hung
                    </a>
                      <span className="text-black-50 d-block">
                        One-Page Profile (Term 1 1st Draft)
                    </span>
                    </div>
                  </td>
                  <td className="text-left">
                    <span className="font-weight-bold text-primary">3 Jan 2021</span>
                  </td>

                  <td className="text-center">
                    <div>
                      <TextField
                        fullWidth
                        className="m-2"
                        id="outlined-select-currency"
                        select
                        value={action}
                        onChange={handleChange}
                        variant="outlined">
                        {actions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </td>

                  <td className="text-center">
                    <Button className="btn-neutral-success mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                      <FontAwesomeIcon icon={['fas', 'check']} className="font-size-sm" />
                    </Button>
                  </td>
                </tr>
                <tr className="divider"></tr>
              </tbody>
            </Table>
          </div>
          <div className="p-3 d-flex justify-content-center">
            <Pagination className="pagination-primary" count={10} />
          </div>
        </CardContent>
      </Card>
    </>
  );
}
