import React from 'react';
import { Button } from '@material-ui/core';
import { PageTitle } from 'layout-components';
import { NavLink } from 'react-router-dom';
import Script from "react-inline-script";
import GoogleBtn from 'components/GoogleBtn'
import logo from '../../assets/images/lscLogo.png';

export default function MoodTrackerPage() {
  return (
    <>

      <div className="bg-light-pure" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent:'center', height:'100vh' }}>

        <h3 className="mb-5 py-2 font-weight-bold" style={{color:'white'}}>Good Morning, Lasallian!</h3>

        <img className="mb-5 py-2" style={{width: 'auto', maxHeight:'40%'}} src={logo} />

        <NavLink
          to="/Dashboard">
          <div className="text-center my-3">
            {/*<Button variant="contained" className="m-2 btn-transition-none0 font-weight-bold font-size-lg ">
              Login with Google
        </Button>*/}
            <GoogleBtn/>
          </div>
        </NavLink>
      </div>
    </>
  );
}
