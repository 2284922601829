import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { PageTitle } from 'layout-components';
import { NavLink } from 'react-router-dom';
import { Grid, TextField, InputLabel, Card, MenuItem, FormControl, Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { ExampleWrapperSimple } from 'layout-components';
import InputMask from './component/InputMask';
import FileUpload from './component/FileUpload';


const marks = [
  {
    value: -5,
    label: 'I dont like it.'
  },
  {
    value: -2.5,
    label: 'Not Really'
  },
  {
    value: 0,
    label: 'It is okay.'
  },
  {
    value: 2.5,
    label: 'I Like it.'
  },
  {
    value: 5,
    label: 'It is awesome!'
  }
];

function valuetext(value) {
  return <span>{{ value }}</span>;
}

export default function MoodChart() {

  const [source, setSource] = useState('');

  const handleChange = (event) => {
    setSource(event.target.value);
  };

  return (
    <>
      <PageTitle
        titleHeading="Good Deeds"
        titleDescription="Discover the beauty of life..."
      />

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        When did it happened?
      </h4>
      <InputMask />
      <br></br>
      <br></br>
      
      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        Where?
      </h4>
      <ExampleWrapperSimple>
        <TextField
          className="my-3"
          fullWidth
          id="filled-textarea"
          label=""
          placeholder=""
        />
      </ExampleWrapperSimple>

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        Who was/ were there?
      </h4>
      <ExampleWrapperSimple>
        <TextField
          className="my-3"
          fullWidth
          id="filled-textarea"
          label=""
          placeholder=""
        />
      </ExampleWrapperSimple>


      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        What happened?
      </h4>
      <ExampleWrapperSimple>
        <TextField
          className="my-3"
          fullWidth
          id="filled-textarea"
          label=""
          placeholder=""
          multiline
          rowsMax="4"
        />
      </ExampleWrapperSimple>

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        How do you feel about that?
      </h4>
      <ExampleWrapperSimple>
        <TextField
          className="my-3"
          fullWidth
          id="filled-textarea"
          label=""
          placeholder=""
          multiline
          rowsMax="4"
        />
      </ExampleWrapperSimple>

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
      Any files upload? (PDF/PNG/JPG only)
      <FileUpload></FileUpload>
      </h4>
      <br></br>
      <br></br>

      <NavLink
        to="/Dashboard">
        <div className="text-center mb-4">
          <Button className="btn-primary text-uppercase font-weight-bold font-size-sm my-3">
            Submit
        </Button>
        </div>
      </NavLink>
    </>
  );
}
