import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, } from '@material-ui/core';
import { PageTitle } from 'layout-components';
import { NavLink } from 'react-router-dom';
import { Grid, TextField, InputLabel, Card, MenuItem, FormControl, Select } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { ExampleWrapperSimple } from 'layout-components';
import InputMask from './component/InputMask';
import axios from 'axios';
import UserContext from 'providers/UserContext';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import DialogContentText from '@material-ui/core/DialogContentText';

const marks = [
  {
    value: -5,
    label: 'I dont like it.'
  },
  {
    value: -2.5,
    label: 'Not Really'
  },
  {
    value: 0,
    label: 'It is okay.'
  },
  {
    value: 2.5,
    label: 'I Like it.'
  },
  {
    value: 5,
    label: 'It is awesome!'
  }
];

function valuetext(value) {
  return <span>{{ value }}</span>;
}

export default function MoodChart() {

  const user = React.useContext(UserContext)
  const created_date = Date.now();
  const [title, setTitle] = useState('');
  const [source, setSource] = useState('');
  const [readDate, setReadDate] = useState(new Date());
  const handleDateChange = (date) => {
    setReadDate(date);
  };
  const [likeValue, setlikeValue] = useState('0');
  const [message, setMessage] = useState('');
  const [knowMore, setKnowMore] = useState('');

  const handleChange = (event) => {
    setSource(event.target.value);
  };

  // Modal
  const [open3, setOpen3] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const handleClickOpen3 = (scrollType) => () => {
    setOpen3(true);
    setScroll(scrollType);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open3) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open3]);

  return (
    <>
      <PageTitle
        titleHeading="SSR Record"
        titleDescription=""
      />

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        Title
      </h4>
      <ExampleWrapperSimple>
        <TextField
          className="my-3"
          fullWidth
          id="filled-textarea"
          label=""
          placeholder=""
          onChange={(event) => {
            event.persist(
            )
            setTitle(event.target.value)
          }}
        />
      </ExampleWrapperSimple>

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        Source
      </h4>
      <ExampleWrapperSimple>
        <FormControl fullWidth variant="standard">
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={source}
            onChange={handleChange}
            label="Age">
            <MenuItem value={'online reading materials'}>Online Reading Materials</MenuItem>
            <MenuItem value={'e-class'}>E-Class</MenuItem>
            <MenuItem value={'books'}>Books</MenuItem>
          </Select>
        </FormControl>
      </ExampleWrapperSimple>

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        When did you read it?
      </h4>
      <ExampleWrapperSimple>
        <div className="d-flex align-items-center flex-wrap">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="m-4">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label=""
                value={readDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
      </ExampleWrapperSimple>

      <h4 className="font-size-lg mb-0 mt-5 py-3 font-weight-bold">
        Do you like the book / article?
      </h4>

      <ExampleWrapperSimple>
        <Grid container spacing={10} justify="center">
          <Grid item md={10}>
            <Slider
              className="slider-primary my-3"
              defaultValue={0}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              step={2.5}
              marks={marks}
              min={-5}
              max={5}
                onChange={(e, v) => {
                  setlikeValue(v)
                  console.log(v)
                }}
            />
          </Grid>
        </Grid>
      </ExampleWrapperSimple>

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        What are the messages discussed?
      </h4>
      <ExampleWrapperSimple>
        <TextField
          className="my-3"
          fullWidth
          id="filled-textarea"
          label=""
          placeholder=""
          multiline
          rowsMax="4"
          onChange={(event) => {
            event.persist(
            )
            setMessage(event.target.value)
          }}
        />
      </ExampleWrapperSimple>

      <h4 className="font-size-lg mb-0 py-3 font-weight-bold">
        What else do you want to know about this topic?
      </h4>
      <ExampleWrapperSimple>
        <TextField
          className="my-3"
          fullWidth
          id="filled-textarea"
          label=""
          placeholder=""
          multiline
          rowsMax="4"
          onChange={(event) => {
            event.persist(
            )
            setKnowMore(event.target.value)
          }}
        />
      </ExampleWrapperSimple>


      <div className="text-center mb-4">
        <Button onClick={handleClickOpen3('paper')} className="btn-primary text-uppercase font-weight-bold font-size-sm my-3">
          Submit
        </Button>
        <Dialog
          open={open3}
          onClose={handleClose3}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description">
          <DialogTitle id="scroll-dialog-title">Confirm submission</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}>
              Title: {title}<br></br>
              Source: {source}<br></br>
              When did you read it: {readDate.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}<br></br>
              Do you like the book/article: {likeValue}<br></br>
              What are the messages discussed: {message}<br></br>
              What do you want to know more about this topic?: {knowMore}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="bg-secondary p-4">
            <Button
              onClick={handleClose3}
              size="small"
              className="btn-outline-dark mr-3">
              Cancel
            </Button>
            <Button
              onClick={
                async () => {
                  const url = `https://script.google.com/macros/s/AKfycbw6NjUiuuK3ajoNJIrX8PixU5XqWtLzCaEmdor6uAvwiPN0UTlNSe3bs2nPNCy2S-y-/exec?action=insert&table=202021&data={"timestamp":"${new Date(created_date).toLocaleString()}","user":"${user.userProps.email}","Title":"${title}","Source":"${source}","When%20did%20you%20read%20it?":"${readDate}","Do%20you%20like%20the%20book%20/%20article?":"${likeValue}","What are the messages discussed?":"${message}","What do you want to know more about this topic?":"${knowMore}"}`
                  await axios.get(url)
                  window.location.href='Dashboard'
                }
              }
              className="btn-success">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>

    </>
  );
}
