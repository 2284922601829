import React from 'react';

import { PageTitle } from 'layout-components';

import WhatsNew from './component/whats-new';
import BadgeCarousels from'./component/badge-carousels';
import Lessons from'./component/lessons';
import Tasks from'./component/tasks';
import WaitingApproval from './component/waiting-approve';

import DashboardCommerce3 from '../../example-components/DashboardCommerce/DashboardCommerce3';
import DashboardCommerce4 from '../../example-components/DashboardCommerce/DashboardCommerce4';
import DashboardCommerce5 from '../../example-components/DashboardCommerce/DashboardCommerce5';
import DashboardCommerce6 from '../../example-components/DashboardCommerce/DashboardCommerce6';
import { withUser } from '../../providers/withUser';
import UserContext from '../../providers/UserContext';
function DashboardCommerce(props) {
  const user = React.useContext(UserContext)
  return (
    <>

      <WhatsNew />
      {/* {user.userProps.role === 'student' ? <BadgeCarousels/>:<></>} */}
      {<Lessons/>}
      {/* {user.userProps.role === 'student' ? <Tasks/>:<></>} */}
      {/* {user.userProps.role === 'teacher' ? <WaitingApproval/> : <></>} */}

      {/*<DashboardCommerce3 />
      <DashboardCommerce4 />
      <DashboardCommerce5 />
      <DashboardCommerce6 />*/
      }
    </>
  );
}

export default DashboardCommerce
