import React, { useState } from 'react';
import {withUser} from 'providers/withUser.tsx'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Badge, Menu, Button, Tooltip, Divider } from '@material-ui/core';

import avatar2 from '../../assets/images/avatars/avatar2.jpg';
import { NavLink } from 'react-router-dom';
import FormsUpload5 from './FormsUpload5';
import UserContext from '../../providers/UserContext';

const SidebarUserbox = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const user = React.useContext(UserContext)

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="app-sidebar--userbox" style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
        <div className="avatar-icon-wrapper avatar-icon-md">
          <Badge
            classes={{ badge: ' badge-circle' }}>
            <div className="avatar-icon rounded-circle">
              <img alt="..." src={user.user.imageUrl} />
            </div>
          </Badge>
        </div>
        {/* <FormsUpload5></FormsUpload5> */}
        <div className="my-3 userbox-details">
          <span>{user.user.name}</span>
          <br></br>
          <span>{user.userProps.Class}</span>
          &nbsp;
          <span>({user.userProps.ClassNo || user.userProps.role})</span>
          <small className="d-block text-white-50">
            {user.user.email}
          </small>
        </div>
      </div>
    </>
  );
};

export default SidebarUserbox;
